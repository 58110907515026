.DisplayGrid {
    display: flex;
    flex-direction: column;
    &.fullHeight {
        height: 100%;
    }

    .innerCellValue {
        width: 2rem;
    }
    .cellValue{
        width: 100%;
    }
    .cellValue.number{
        text-align: right;
        padding-right: 5%;
    }
    .cellValue.adorment {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
    }

    .vertBreak {
        height: 20px;
        border-left: 1px rgb(224, 224, 224) solid;
    }

    .colLabel {
        flex: 1;
    }

    .columnHeader {
        position: sticky;
        top: 0;
        z-index: 1;
        background: white;
    }

    .row,.columnHeader {
        display: flex;
        min-height: 52px;
        // max-height: 52px;
        border-bottom: 1px rgb(224, 224, 224) solid;
    }

    .row.showAdditionalRowData {
        border-bottom: 1px rgb(78, 78, 78) solid;
    }

    .body {
        overflow: hidden;
        flex-grow: 1;
        position: relative;
    }
    &.fullHeight .innerBody{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .innerBody {
        overflow: auto;
    }

    // .rowContainer:hover {
    //     background-color: rgba(0, 0, 0, 0.04);
    // }
    .rowContainer.odd {
        background-color: rgba(0, 0, 0, 0.02);
    }

    .cell, .column {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0 5px 0 5px;
        overflow: hidden;
    }

    @media screen and (max-width: 680px) {
        font-size: 3vw!important;
        svg {
            font-size: 3vw!important;
        }

        .cell, .column {
            padding: 0 3px 0 3px;
        }
    }

    @media screen and (max-height: 620px) {
        .innerBody {
            position: static;
        }
    }
}